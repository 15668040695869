import api from 'src/explore/services/api';
// Actions

export const FETCH = 'cart/FETCH';
export const FETCH_SUCCESS = 'cart/FETCH_SUCCESS';
export const FETCH_FAILURE = 'cart/FETCH_FAILURE';

export const FETCH_EGIFT_SUCCESS = 'cart/FETCH_EGIFT_SUCCESS';

// Action creators

function fetchSuccess( cart ) {
  return { type: FETCH_SUCCESS, payload: { data: cart }};
}

function fetchFailure( error ) {
  return { type: FETCH_FAILURE, payload: error };
}

// Updated non-react stuff
function updateCartItemsCount( count ) {
  if ( typeof $ === 'undefined' ) {
    return;
  }

  const cartIcon = $( '.js__header-cart-link' );
  const cartCountLabel = $( '.js__header-cart-items-count' );

  if ( count > 0 ) {
    cartIcon.addClass( 'active' );
    cartCountLabel.html( count );
    cartCountLabel.addClass( 'active' );
  } else {
    cartIcon.removeClass( 'active' );
    cartCountLabel.html( '' );
    cartCountLabel.removeClass( 'active' );
  }

  cartIcon.prop( 'title', `You have ${count} ${count === 1 ? 'item' : 'items'} in your cart.` );
}

export function fetch( egifting = false ) {
  return ( dispatch ) => {
    dispatch({ type: FETCH });

    const url = egifting ? 'egiftings/purchaser/cart' : 'cart';
    const request = api.get( url );

    return request
      .then(( response ) => {
        const itemsCount = response.data.number_of_items || 0;

        dispatch( fetchSuccess( response.data ));
        updateCartItemsCount( itemsCount );
      })
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( fetchFailure( error.response.data ));
        }
      });
  };
}

export function fetchEGiftSuccess( egift ) {
  return { type: FETCH_EGIFT_SUCCESS, payload: { data: egift }};
}
