import { combineReducers } from 'redux';

import { HIDE as HIDE_BACKDROP } from 'src/explore/elements/Backdrop/actions';

import { OPEN, CLOSE, SWITCH_MODE } from './actions';
import Login from './scenes/Login/reducer';
import Signup from './scenes/Signup/reducer';

const visibleReducer = ( state: boolean = false, action: { type: string }) => {
  switch ( action.type ) {
    case OPEN:
      return true;
    case CLOSE:
    case HIDE_BACKDROP:
      return false;
    default:
      return state;
  }
};

const modeReducer = ( state: string = 'login', action: { type: string; payload: string }) => {
  switch ( action.type ) {
    case SWITCH_MODE:
      return action.payload;
    default:
      return state;
  }
};

const redirectToReducer = (
  state: string | null = null,
  action: { type: string; payload: { redirectTo: string } | null }
) => {
  switch ( action.type ) {
    case OPEN:
      return action.payload.redirectTo;
    case CLOSE:
      return null;
    default:
      return state;
  }
};

const defaultEmailReducer = (
  state: string | null = null,
  action: { type: string; payload: { defaultEmail: string } | null }
) => {
  switch ( action.type ) {
    case OPEN:
      return action.payload.defaultEmail;
    case CLOSE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  Login,
  Signup,
  defaultEmail: defaultEmailReducer,
  mode: modeReducer,
  visible: visibleReducer,
  redirectTo: redirectToReducer,
});
