import { combineReducers } from 'redux';

import account from './account/reducer';
import accountFavoriteProducts from './account_favorite_products/reducer';
import admin from './admin/reducer';
import cart from './cart/reducer';
import giftCardProduct from './gift_card_product/reducer';
import purchaseRewardTiers from './purchase_reward_tiers/reducer';
import rewardPromos from './reward_promos/reducer';
import viewedProducts from './viewed_products/reducer';

// Fake properties (TODO: this smells)
const settings = ( state = {}) => state;
const specialDate = ( state = null ) => state;

export default combineReducers({
  account,
  accountFavoriteProducts,
  admin,
  cart,
  giftCardProduct,
  purchaseRewardTiers,
  rewardPromos,
  settings,
  specialDate,
  viewedProducts,
});
