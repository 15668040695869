import api, { ApiError } from 'src/explore/services/api';
import { reset } from 'redux-form';

import { fetch as fetchCart } from '../actions';

// Actions

export const CREATE = 'cart/promo_codes/CREATE';
export const CREATE_SUCCESS = 'cart/promo_codes/CREATE_SUCCESS';
export const CREATE_FAILURE = 'cart/promo_codes/CREATE_FAILURE';

export const DESTROY = 'cart/promo_codes/DESTROY';
export const DESTROY_SUCCESS = 'cart/promo_codes/DESTROY_SUCCESS';
export const DESTROY_FAILURE = 'cart/promo_codes/DESTROY_FAILURE';

// Action creators

export function createSuccess( code ) {
  return { type: CREATE_SUCCESS, payload: code };
}

export function createFailure( error ) {
  return { type: CREATE_FAILURE, payload: error };
}

export function create( params, egifting = false ) {
  return ( dispatch ) => {
    dispatch({ type: CREATE });

    const request = api.post( 'discount_code', params );

    return request
      .then(( response ) => {
        dispatch( createSuccess( response.data ));

        if ( /^\/buy\/confirmation\/new\/?/.test( window.location.pathname )) {
          window.location.reload()
        } else {
          // TODO: not sure of a better way to do this
          dispatch( fetchCart( egifting ));
          dispatch( reset( 'discount_code' ));
        }

        return response.data;
      })
      .catch(( error ) => {
        const apiError = new ApiError( error.response );

        dispatch( createFailure( apiError ));

        throw apiError;
      });
  };
}

export function destroySuccess() {
  return { type: DESTROY_SUCCESS };
}

export function destroyFailure( error ) {
  return { type: DESTROY_FAILURE, payload: error };
}

export function destroy( egifting = false ) {
  return ( dispatch ) => {
    dispatch({ type: DESTROY });

    const request = api.delete( 'discount_code' );

    return request
      .then(() => {
        dispatch( destroySuccess());
        
        if ( /^\/buy\/confirmation\/new\/?/.test( window.location.pathname )) {
          window.location.reload()
        } else {
          // TODO: not sure of a better way to do this
          dispatch( fetchCart( egifting ));
        }
      })
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( destroyFailure( error.response.data ));
        }
      });
  };
}
