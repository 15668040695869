import { Recombee } from 'src/explore/services/recommendations/Recombee';

// Actions

export const FETCH = 'viewed_products/FETCH';
export const FETCH_SUCCESS = 'viewed_products/FETCH_SUCCESS';
export const FETCH_FAILURE = 'viewed_products/FETCH_FAILURE';

// Action creators

function fetchSuccess( products ) {
  return { type: FETCH_SUCCESS, payload: { data: products }};
}

function fetchFailure( apiError ) {
  return { type: FETCH_FAILURE, payload: apiError };
}

const recombee = new Recombee();

export function fetch( identity ) {
  return ( dispatch ) => {
    dispatch({ type: FETCH });

    return recombee
      .getRecentlyViewedForUser( identity, 16, { scenario: 'recently_viewed' })
      .then( async ( res ) => {
        const recommendedProducts = await Recombee.extractJson( res );
        dispatch( fetchSuccess( recommendedProducts ));
      })
      .catch(() => {
        dispatch( fetchFailure());
      });
  };
}
