const setVisibility = ( visible ) => {
  if ( typeof window.zE === 'undefined' ) {
    return;
  }

  if ( visible ) {
    window.zE( 'webWidget', 'show' );
  } else {
    window.zE( 'webWidget', 'hide' );
  }
};

export default {
  setVisibility,
};
