// Actions

export const OPEN = 'Cart/elements/DiscountCodes/OPEN';
export const CLOSE = 'Cart/elements/DiscountCodes/CLOSE';
export const TOGGLE = 'Cart/elements/DiscountCodes/TOGGLE';
export const CLEAR_ERRORS = 'Cart/elements/DiscountCodes/CLEAR_ERRORS';

// Action creators

export function open() {
  return { type: OPEN };
}

export function close() {
  return { type: CLOSE };
}

export function toggle() {
  return { type: TOGGLE };
}

export function clearErrors() {
  return { type: CLEAR_ERRORS };
}
