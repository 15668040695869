import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import AccountStats from './scenes/AccountStats/reducer';
import Auth from './compositions/Auth/reducer';
import Backdrop from './elements/Backdrop/reducer';
import Cart from './compositions/Cart/reducer';
import Confirmation from './scenes/Confirmation/reducer';
import data from './data/reducer';
import EGiftCardForm from './compositions/EGiftCardForm/reducer';
import ForgotPassword from './scenes/ForgotPassword/reducer';
import Payment from './scenes/Payment/reducer';
import Shipping from './scenes/Shipping/reducer';
import SiteHeader from './compositions/SiteHeader/reducer';
import { reducer as VideoModal } from './scenes/VideoModal/reducer';

// Fake reducer
const config = ( state = {}) => state;

export default combineReducers({
  config,
  form: formReducer, // By default redux-form expects it to be 'form'
  AccountStats,
  Auth,
  Backdrop,
  Cart,
  Confirmation,
  data,
  EGiftCardForm,
  ForgotPassword,
  Payment,
  Shipping,
  SiteHeader,
  VideoModal,
});
