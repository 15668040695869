import { TOGGLE } from './actions';

const initialState = {
  open: false,
};

const openReducer = ( action: { type: string; payload: boolean }) => {
  switch ( action?.type ) {
    case TOGGLE:
      return !!action.payload;
    default:
      return false;
  }
};

export const reducer = ( state = initialState, action: { type: string; payload: boolean }) => {
  switch ( action.type ) {
    default:
      return {
        ...state,
        open: openReducer( action ),
      };
  }
};

export default reducer;
