import api from 'src/explore/services/api';

// Actions

export const UPDATE = 'Shipping/data/shipping_address/UPDATE';
export const UPDATE_SUCCESS = 'Shipping/data/shipping_address/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'Shipping/data/shipping_address/UPDATE_FAILURE';

// Action creators

function updateSuccess() {
  return { type: UPDATE_SUCCESS };
}

function updateFailure( error ) {
  return { type: UPDATE_FAILURE, payload: error };
}

export function update( params ) {
  return ( dispatch ) => {
    dispatch({ type: UPDATE, ...params });

    const { ...shippingAddress } = params.shipping_address || {};

    if ( params.shipping_address?.id !== undefined ) {
      delete shippingAddress.id;
    }

    const request = api.put( 'shipping_address', shippingAddress );

    return request
      .then(() => dispatch( updateSuccess()))
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( updateFailure( error.response.data ));
        }
      });
  };
}
