import { SIGN_UP, SIGN_UP_SUCCESS, SIGN_UP_FAILURE } from 'src/explore/data/auth/actions';

const loadingReducer = ( state: boolean = false, action: { type: string }) => {
  switch ( action.type ) {
    case SIGN_UP:
      return true;
    case SIGN_UP_SUCCESS:
    case SIGN_UP_FAILURE:
      return false;
    default:
      return state;
  }
};

export default ( state: { loading?: boolean } = {}, action: { type: string }) => ({
  loading: loadingReducer( state.loading, action ),
});
