import apiReducer, { initialState } from 'src/shared/reducers/api_data';

import { FETCH, FETCH_SUCCESS, FETCH_FAILURE, FETCH_EGIFT_SUCCESS } from './actions';
import { CREATE_SUCCESS as CREATE_COMMENT_SUCCESS } from './comment/actions';

const cartApiReducer = apiReducer({
  initActions: [ FETCH ],
  successActions: [ FETCH_SUCCESS ],
  failureActions: [ FETCH_FAILURE ],
});

const cartReducer = ( state, action ) => {
  switch ( action.type ) {
    case FETCH_EGIFT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          purchased_egift: {
            ... state.data.purchased_egift || {},
            ...action.payload.data,
          },
        }
      };
    default:
      return cartApiReducer( state, action );
  }
};

const commentReducer = ( state, action ) => {
  switch ( action.type ) {
    case CREATE_COMMENT_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

function containsGiftCard( cart ) {
  if ( !cart ) return false;

  if ( cart.gift_cards && cart.gift_cards.length ) return true;

  let hasGiftCard = false;

  const subcarts = cart.subcarts || [];

  subcarts.forEach(( carts ) =>
    carts.cart_items.forEach(( cartItem ) => {
      if ( cartItem.product.product_type === 'gift_card' ) hasGiftCard = true;
    })
  );

  return hasGiftCard;
}

function containsMonthlySubscription( cart ) {
  if ( !cart ) return false;

  let hasMonthlySubscription = false;

  const subcarts = cart.subcarts || [];

  subcarts.forEach(( carts ) =>
    carts.cart_items.forEach(( cartItem ) => {
      if ( cartItem.product.is_recurring_subscription ) hasMonthlySubscription = true;
    })
  );

  return hasMonthlySubscription;
}

export default ( state = initialState, action ) => {
  const newState = cartReducer( state, action );

  return {
    ...newState,
    comment: commentReducer( newState.comment, action ),
    containsGiftCard: containsGiftCard( newState.data ),
    containsMonthlySubscription: containsMonthlySubscription( newState.data ),
  };
};
