import apiReducer from 'src/shared/reducers/api_data';

import { FETCH, FETCH_SUCCESS, FETCH_FAILURE } from './actions';
import { LOGIN_SUCCESS, SIGN_UP_SUCCESS } from '../auth/actions';

export default apiReducer({
  initActions: [ FETCH ],
  successActions: [ FETCH_SUCCESS, LOGIN_SUCCESS, SIGN_UP_SUCCESS ],
  failureActions: [ FETCH_FAILURE ],
});
