import api from 'src/explore/services/api';
import ApiError from 'src/explore/services/api/ApiError';

import { fetch as fetchCart } from '../cart/actions';

api.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'; // TODO: Backend expects it to be XHR

// Actions

export const EMAIL_AUTHS = 'auth/EMAIL_AUTHS';
export const EMAIL_AUTHS_SUCCESS = 'auth/EMAIL_AUTHS_SUCCESS';
export const EMAIL_AUTHS_FAILURE = 'auth/EMAIL_AUTHS_FAILURE';
export const SIGN_UP = 'auth/SIGN_UP';
export const SIGN_UP_SUCCESS = 'auth/SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'auth/SIGN_UP_FAILURE';
export const LOGIN = 'auth/LOGIN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';
export const FORGOT_PASSWORD = 'auth/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'auth/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'auth/FORGOT_PASSWORD_FAILURE';

// Action creators

function accountStatusSuccess( response ) {
  return { type: EMAIL_AUTHS_SUCCESS, payload: { data: response }};
}

function accountStatusFailure( apiError ) {
  return { type: EMAIL_AUTHS_FAILURE, payload: apiError };
}
export function emailAuth( email ) {
  return ( dispatch ) => {
    dispatch({ type: EMAIL_AUTHS });

    const request = api.get( 'email_auths', { params: { email }});

    return request
      .then(( response ) => {
        dispatch( accountStatusSuccess( response.data ));

        return response.data;
      })
      .catch(( error ) => {
        const apiError = new ApiError( error.response );

        dispatch( accountStatusFailure( apiError ));

        throw apiError;
      });
  };
}

function signUpSuccess( response ) {
  return { type: SIGN_UP_SUCCESS, payload: { data: response }};
}

function signUpFailure( apiError ) {
  return { type: SIGN_UP_FAILURE, payload: apiError };
}
export function signUp( params ) {
  return ( dispatch ) => {
    dispatch({ type: SIGN_UP });

    const request = api.post( '/sign-up.json', params, { baseURL: '' });

    return request
      .then(( response ) => {
        dispatch( signUpSuccess( response.data.data ));
        dispatch( fetchCart());

        return response.data;
      })
      .catch(( error ) => {
        const apiError = new ApiError( error.response );

        dispatch( signUpFailure( apiError ));

        throw apiError;
      });
  };
}

function loginSuccess( account, meta ) {
  return { type: LOGIN_SUCCESS, payload: { data: account, meta }};
}

function loginFailure( apiError ) {
  return { type: LOGIN_FAILURE, payload: apiError };
}
export function login( params ) {
  return ( dispatch ) => {
    dispatch({ type: LOGIN });

    const request = api.post( '/log-in.json', params, { baseURL: '' });

    return request
      .then(( response ) => {
        dispatch( loginSuccess( response.data.data, response.data.meta ));
        dispatch( fetchCart());

        return response.data;
      })
      .catch(( error ) => {
        const apiError = new ApiError( error.response );

        dispatch( loginFailure( apiError ));

        throw apiError;
      });
  };
}

function forgotPasswordSuccess( response ) {
  return { type: FORGOT_PASSWORD_SUCCESS, payload: response };
}

function forgotPasswordFailure( apiError ) {
  return { type: FORGOT_PASSWORD_FAILURE, payload: apiError };
}
export function forgotPassword( params ) {
  return ( dispatch ) => {
    dispatch({ type: FORGOT_PASSWORD });

    const request = api.post( '/forgot-password.json', params, { baseURL: '' });

    return request
      .then(( response ) => dispatch( forgotPasswordSuccess( response.data )))
      .catch(( error ) => {
        const apiError = new ApiError( error.response );

        dispatch( forgotPasswordFailure( apiError ));

        throw apiError;
      });
  };
}
