import {
  FETCH as FETCH_CART,
  FETCH_SUCCESS as FETCH_CART_SUCCESS,
  FETCH_FAILURE as FETCH_CART_FAILURE,
} from 'src/explore/data/cart/actions';
import { HIDE as HIDE_BACKDROP } from 'src/explore/elements/Backdrop/actions';

import { OPEN, CLOSE, FIX_HEADER } from './actions';
import { reducer as commentReducer } from './elements/Comment/reducer';
import { reducer as promoCodeReducer } from './elements/DiscountCodes/reducer';

const fixCheckoutReducer = ( state = false, action ) => {
  switch ( action.type ) {
    case CLOSE:
    case HIDE_BACKDROP:
      return false;
    case FIX_HEADER:
      return action.payload;
    default:
      return state;
  }
};

const loadingReducer = ( state = false, action ) => {
  switch ( action.type ) {
    case FETCH_CART:
      return true;
    case FETCH_CART_SUCCESS:
    case FETCH_CART_FAILURE:
      return false;
    default:
      return state;
  }
};

const visibleReducer = ( state = false, action ) => {
  switch ( action.type ) {
    case OPEN:
      return true;
    case CLOSE:
    case HIDE_BACKDROP:
      return false;
    default:
      return state;
  }
};

export const reducer = ( state = {}, action ) => {
  switch ( action.type ) {
    default:
      return {
        ...state,
        fixCheckout: fixCheckoutReducer( state.fixCheckout, action ),
        loading: loadingReducer( state.loading, action ),
        visible: visibleReducer( state.visible, action ),
        Comment: commentReducer( state.Comment, action ),
        PromoCode: promoCodeReducer( state.PromoCode, action ),
      };
  }
};

export default reducer;
