import { ADD, ADD_SUCCESS, ADD_FAILURE } from './actions';

const initialState = {
  loading: false,
  errors: null,
};

const loadingReducer = ( state, action ) => {
  switch ( action.type ) {
    case ADD:
      return true;
    case ADD_SUCCESS:
    case ADD_FAILURE:
      return false;
    default:
      return state;
  }
};

const errorsReducer = ( state, action ) => {
  switch ( action.type ) {
    case ADD_SUCCESS:
      return null;
    case ADD_FAILURE:
      return [ action.payload.message ];
    default:
      return state;
  }
};

export const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    default:
      return {
        ...state,
        loading: loadingReducer( state.loading, action ),
        errors: errorsReducer( state.errors, action ),
      };
  }
};

export default reducer;
