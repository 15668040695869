// Actions

export const OPEN = 'Auth/OPEN';
export const CLOSE = 'Auth/CLOSE';
export const SWITCH_MODE = 'Auth/SWITCH_MODE';

// Action creators

export function open( redirectTo = null, defaultEmail = null ) {
  return ( dispatch ) => {
    dispatch({ type: OPEN, payload: { redirectTo, defaultEmail }});
  };
}

export function close() {
  return ( dispatch ) => {
    dispatch({ type: CLOSE });
  };
}

export function switchMode( mode ) {
  return { type: SWITCH_MODE, payload: mode };
}
