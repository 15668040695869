import {
  FETCH_SUCCESS, CREATE, DESTROY, CREATE_FAILURE, DESTROY_FAILURE,
} from './actions';

export default ( state = {}, action ) => {
  switch ( action.type ) {
    case FETCH_SUCCESS:
      return action.payload;
    case CREATE:
      return { ...state, [action.payload]: true };
    case CREATE_FAILURE:
      return { ...state, [action.payload]: false };
    case DESTROY:
      return { ...state, [action.payload]: false };
    case DESTROY_FAILURE:
      return { ...state, [action.payload]: true };
    default:
      return state;
  }
};
