import api from 'src/explore/services/api';
import Deserializer from 'src/explore/services/api/Deserializer';
import { Recombee } from 'src/explore/services/recommendations/Recombee';
import * as authComponentActionCreators from 'src/explore/compositions/Auth/actions';

// Actions

export const FETCH = 'account_favorite_products/FETCH';
export const FETCH_SUCCESS = 'account_favorite_products/FETCH_SUCCESS';
export const FETCH_FAILURE = 'account_favorite_products/FETCH_FAILURE';

export const CREATE = 'account_favorite_products/CREATE';
export const CREATE_SUCCESS = 'account_favorite_products/CREATE_SUCCESS';
export const CREATE_FAILURE = 'account_favorite_products/CREATE_FAILURE';

export const DESTROY = 'account_favorite_products/DESTROY';
export const DESTROY_SUCCESS = 'account_favorite_products/DESTROY_SUCCESS';
export const DESTROY_FAILURE = 'account_favorite_products/DESTROY_FAILURE';

// Action creators

function fetchSuccess( accountFavoriteProductIds ) {
  return { type: FETCH_SUCCESS, payload: accountFavoriteProductIds };
}

function fetchFailure( error ) {
  return { type: FETCH_FAILURE, payload: error };
}
export function fetch() {
  return ( dispatch ) => {
    dispatch({ type: FETCH });

    const request = api.get( 'account_favorite_products' );

    return request
      .then(( response ) =>
        Deserializer.deserialize( response.data ).then(( result ) => {
          dispatch(
            fetchSuccess(
              result.reduce(( acc, val ) => {
                acc[val.id] = true;
                return acc;
              }, {})
            )
          );
        })
      )
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( fetchFailure( error.response.data ));
        }

        return [];
      });
  };
}

function createSuccess( productId ) {
  return { type: CREATE_SUCCESS, payload: productId };
}

function createFailure( error ) {
  return { type: CREATE_FAILURE, payload: error };
}
export function create( productId, identity, recommId, account ) {
  return ( dispatch ) => {
    if ( !account ) {
      dispatch( authComponentActionCreators.open());
      return;
    }

    dispatch({ type: CREATE, payload: productId });

    const request = api.post( 'account_favorite_products', { product_id: productId });

    return request
      .then(() => {
        dispatch( createSuccess( productId ));

        // Track analytics
        if ( typeof window.fbq !== 'undefined' ) {
          window.fbq( 'track', 'AddToWishlist', {
            content_ids: [ productId ],
            content_type: 'product',
          });
        }

        window.analytics.track(
          'Product Added To Favorites',
          {},
          {
            integrations: {
              All: false,
              'Google Tag Manager': true,
              'Facebook Pixel': true
            },
          }
        );

        new Recombee().addFavorite( identity, productId, { recommId });
      })
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( createFailure( error.response.data ));
        }
      });
  };
}

function destroySuccess( productId ) {
  return { type: DESTROY_SUCCESS, payload: productId };
}

function destroyFailure( error ) {
  return { type: DESTROY_FAILURE, payload: error };
}

export function destroy( productId ) {
  return ( dispatch ) => {
    dispatch({ type: DESTROY, payload: productId });

    const request = api.delete( `account_favorite_products/${productId}` );

    return request
      .then(() => {
        dispatch( destroySuccess( productId ));

        window.analytics.track(
          'Product Removed From Favorites',
          {},
          {
            integrations: {
              All: false,
              'Google Tag Manager': true,
              'Facebook Pixel': true
            },
          }
        );
      })
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( destroyFailure( error.response.data ));
        }
      });
  };
}
