// Actions

export const SHOW = 'VideoModal/SHOW';
export const HIDE = 'VideoModal/HIDE';

// Action creators

export function show( src ) {
  return { type: SHOW, payload: { src }};
}

export function hide() {
  return { type: HIDE };
}
