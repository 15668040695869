import api from 'src/explore/services/api';
import GA, { EVENT_CC_ADDED } from 'src/explore/services/ga';

// Actions

export const CREATE = 'Payment/data/imprint/CREATE';
export const CREATE_SUCCESS = 'Payment/data/imprint/CREATE_SUCCESS';
export const CREATE_FAILURE = 'Payment/data/imprint/CREATE_FAILURE';

export const FETCH = 'Payment/data/imprint/FETCH';
export const FETCH_SUCCESS = 'Payment/data/imprint/FETCH_SUCCESS';
export const FETCH_FAILURE = 'Payment/data/imprint/FETCH_FAILURE';

// Action creators

// HACK: we receive Stripe (yes, Stripe) in order to update the state
// using redux, and not on the view itself
export function create( stripe, billingInfo ) {
  return ( dispatch ) => {
    dispatch({ type: CREATE });

    return stripe.createToken().then(( result ) => {
      if ( result.error ) {
        dispatch( createFailure( result.error ));
      } else {
        const request = api.put( 'payment', {
          payment: {
            card_info: billingInfo.card,
            billing_address: billingInfo.shipping,
            stripe_token: result.token.id,
          },
        });

        return request
          .then(( response ) => {
            GA.trackEvent( EVENT_CC_ADDED );

            dispatch( createSuccess( response.data ));

            return response.data;
          })
          .catch(( error ) => {
            if ( error.response ) {
              dispatch( createFailure( error.response.data ));
            }

            throw error;
          });
      }
    });
  };
}

function createSuccess( imprint ) {
  return { type: CREATE_SUCCESS, payload: { data: imprint }};
}

function createFailure( error ) {
  return { type: CREATE_FAILURE, payload: error };
}

export function fetch() {
  return ( dispatch ) => {
    dispatch({ type: FETCH });

    const request = api.get( 'payment' );

    return request
      .then(( response ) => dispatch( fetchSuccess( response.data )))
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( fetchFailure( error.response.data ));
        } else {
          // TODO: we need to dispatch this, because capybara fails with status 0
          // I don't understand what the hell is wrong in our specs
          dispatch( fetchFailure());
        }
      });
  };
}

function fetchSuccess( imprint ) {
  return { type: FETCH_SUCCESS, payload: { data: imprint }};
}

function fetchFailure( error ) {
  return { type: FETCH_FAILURE, payload: error };
}
