import { CREATE_SUCCESS } from 'src/explore/data/cart/comment/actions';
import { OPEN, CLOSE, TOGGLE } from './actions';

const initialState = {
  open: false,
};

export const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case OPEN:
      return {
        ...state,
        open: true,
      };
    case CLOSE:
    case CREATE_SUCCESS:
      return {
        ...state,
        open: false,
      };
    case TOGGLE:
      return {
        ...state,
        open: !state.open,
      };
    default:
      return state;
  }
};
