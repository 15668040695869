import api from 'src/explore/services/api';
import ApiError from 'src/explore/services/api/ApiError';

// Actions

export const FETCH = 'purchase_reward_tiers/FETCH';
export const FETCH_SUCCESS = 'purchase_reward_tiers/FETCH_SUCCESS';
export const FETCH_FAILURE = 'purchase_reward_tiers/FETCH_FAILURE';

// Action creators

const fetchSuccess = ( promos ) => ({ type: FETCH_SUCCESS, payload: { data: promos }});

const fetchFailure = ( apiError ) => ({ type: FETCH_FAILURE, payload: apiError });

export const fetch = () => ( dispatch ) => {
  dispatch({ type: FETCH });

  const request = api.get( 'purchase_reward/tiers' );

  return request
    .then(( response ) => {
      dispatch( fetchSuccess( response.data ));

      return response.data;
    })
    .catch(( error ) => {
      const apiError = new ApiError( error.response );

      dispatch( fetchFailure( apiError ));

      throw apiError;
    });
};
