import {
  BUILD as BUILD_PAYMENT,
  CANCEL_BUILD as CANCEL_BUILD_PAYMENT,
  STRIPE_ERROR as STRIPE_ERROR_PAYMENT,
} from './actions';
import {
  CREATE as CREATE_IMPRINT,
  CREATE_SUCCESS as CREATE_IMPRINT_SUCCESS,
  CREATE_FAILURE as CREATE_IMPRINT_FAILURE,
  FETCH as FETCH_IMPRINT,
  FETCH_SUCCESS as FETCH_IMPRINT_SUCCESS,
  FETCH_FAILURE as FETCH_IMPRINT_FAILURE,
} from './data/imprint/actions';
import dataReducer from './data/reducer';

const initialState = {
  loading: false,
  errors: null,
  newImprint: false,
};

const loadingReducer = ( state, action ) => {
  switch ( action.type ) {
    case FETCH_IMPRINT:
    case CREATE_IMPRINT:
      return true;
    case CREATE_IMPRINT_SUCCESS:
      return true;
    case CREATE_IMPRINT_FAILURE:
    case FETCH_IMPRINT_SUCCESS:
      return false;
    case FETCH_IMPRINT_FAILURE:
      return false;
    case STRIPE_ERROR_PAYMENT:
      return false;
    default:
      return state;
  }
};

const newImprintReducer = ( state, action ) => {
  switch ( action.type ) {
    case BUILD_PAYMENT:
      return true;
    case CANCEL_BUILD_PAYMENT:
      return false;
    default:
      return state;
  }
};

const errorReducer = ( state, action ) => {
  switch ( action.type ) {
    case STRIPE_ERROR_PAYMENT:
    case CREATE_IMPRINT_FAILURE:
      return [ action.payload.message ];
    case CREATE_IMPRINT_SUCCESS:
      return null;
    case CANCEL_BUILD_PAYMENT:
      return null;
    default:
      return state;
  }
};

export const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    default:
      return {
        ...state,
        loading: loadingReducer( state.loading, action ),
        errors: errorReducer( state.errors, action ),
        data: dataReducer( state.data, action ),
        newImprint: newImprintReducer( state.newImprint, action ),
      };
  }
};

export default reducer;
