import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducer from './reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const init = ( initialState = {}) => createStore(
  reducer,
  Object.assign( initialState, window.__data?.frontendStoreData || {}),
  composeEnhancers( applyMiddleware( thunk )),
);

export const store = init();
