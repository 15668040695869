import { SHOW, HIDE, SHOW_BACKDROP } from './actions';

const initialState = {
  visible: false,
  actionType: null,
};

const visibleReducer = ( state, action ) => {
  switch ( action.type ) {
    case SHOW:
      return { visible: true, actionType: SHOW };
    case HIDE:
      return { visible: false, actionType: null };
    case SHOW_BACKDROP:
      return { visible: true, actionType: SHOW_BACKDROP };
    default:
      return state;
  }
};

export const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    default:
      return {
        ...state,
        ...visibleReducer( state, action ),
      };
  }
};

export default reducer;
