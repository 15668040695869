import { combineReducers } from 'redux';

import { SHOW, HIDE } from './actions';

const visible = ( state = false, action ) => {
  switch ( action.type ) {
    case SHOW:
      return true;
    case HIDE:
      return false;
    default:
      return state;
  }
};

const src = ( state = null, action ) => {
  switch ( action.type ) {
    case SHOW:
      return action.payload?.src;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  visible,
  src,
});
