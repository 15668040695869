import {
  COMPLETE as COMPLETE_CONFIRMATION,
  COMPLETE_FAILURE as COMPLETE_CONFIRMATION_FAILURE,
} from './actions';

const initialState = {
  loading: false,
};

const loadingReducer = ( state, action ) => {
  switch ( action.type ) {
    case COMPLETE_CONFIRMATION:
      return true;
    case COMPLETE_CONFIRMATION_FAILURE:
      return false;
    default:
      return state;
  }
};

export const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    default:
      return {
        ...state,
        loading: loadingReducer( state.loading, action ),
      };
  }
};

export default reducer;
