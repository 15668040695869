import api from 'src/explore/services/api';
import { fetch as fetchCart } from 'src/explore/data/cart/actions';
import { open as openCart } from 'src/explore/compositions/Cart/actions';

// Actions

export const ADD = 'EGiftCardForm/data/ADD';
export const ADD_SUCCESS = 'EGiftCardForm/data/ADD_SUCCESS';
export const ADD_FAILURE = 'EGiftCardForm/data/ADD_FAILURE';

// Action creators

function addSuccess() {
  return { type: ADD_SUCCESS };
}

function addFailure( error ) {
  return { type: ADD_FAILURE, payload: error };
}

export function add( params ) {
  return ( dispatch ) => {
    dispatch({ type: ADD });

    const request = api.post( 'gift_cards', { gift_card: params });

    return request
      .then(() => {
        dispatch( addSuccess());
        dispatch( fetchCart());
        dispatch( openCart());
      })
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( addFailure( error.response.data ));
        }
      });
  };
}
