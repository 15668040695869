import GA, { EVENT_VIEW_CART } from 'src/explore/services/ga';
import siteChatService from 'src/explore/services/siteChat';
import { show as showBackdrop, hide as hideBackdrop } from 'src/explore/elements/Backdrop/actions';

// Actions

export const OPEN = 'Cart/OPEN';
export const CLOSE = 'Cart/CLOSE';
export const FIX_HEADER = 'Cart/FIX_HEADER';

// Action creators

export function open() {
  return ( dispatch ) => {
    dispatch({ type: OPEN });
    dispatch( showBackdrop());

    updateNonReactElements( true );

    // Only specs don't have __data
    if ( window.__data ) {
      GA.trackEvent( EVENT_VIEW_CART, {
        cart_id: window.__data.segmentEcommerceProperties.cart_id,
        products: window.__data.segmentEcommerceProperties.products,
      });
    }
  };
}

export function close() {
  return ( dispatch ) => {
    dispatch({ type: CLOSE });
    dispatch( hideBackdrop());

    updateNonReactElements( false );
  };
}

export const fixHeader = ( isFixed ) => ({ type: FIX_HEADER, payload: isFixed });

function updateNonReactElements( isCartVisible ) {
  siteChatService.setVisibility( !isCartVisible );
  setSmartyStreetsVisibility( !isCartVisible );
}

function setSmartyStreetsVisibility( visible ) {
  if ( typeof $ === 'undefined' ) {
    return;
  }

  if ( visible ) {
    $( '.smarty-ui .smarty-tag' ).show();
  } else {
    $( '.smarty-ui .smarty-tag' ).hide();
  }
}
