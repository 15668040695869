import { show as showBackdrop, hide as hideBackdrop } from 'src/explore/elements/Backdrop/actions';

// Actions

export const OPEN = 'ForgotPassword/OPEN';
export const CLOSE = 'ForgotPassword/CLOSE';

// Action creators

export function open( email = null ) {
  return ( dispatch ) => {
    dispatch({ type: OPEN, payload: email });
    dispatch( showBackdrop());
  };
}

export function close() {
  return ( dispatch ) => {
    dispatch({ type: CLOSE });
    dispatch( hideBackdrop());
  };
}
