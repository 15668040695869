import { combineReducers } from 'redux';

import { OPEN, CLOSE } from './actions';

const visible = ( state = false, action ) => {
  switch ( action.type ) {
    case OPEN:
      return true;
    case CLOSE:
      return false;
    default:
      return state;
  }
};

const defaultEmail = ( state = null, action ) => {
  switch ( action.type ) {
    case OPEN:
      return action.payload || null;
    default:
      return state;
  }
};

export default combineReducers({
  visible,
  defaultEmail,
});
