import api from 'src/explore/services/api';
import ApiError from 'src/explore/services/api/ApiError';
import Deserializer from 'src/explore/services/api/Deserializer';

// Actions

export const FETCH = 'AccountStats/data/topProducts/FETCH';
export const FETCH_SUCCESS = 'AccountStats/data/topProducts/FETCH_SUCCESS';
export const FETCH_FAILURE = 'AccountStats/data/topProducts/FETCH_FAILURE';

// Action creators

function fetchSuccess( stats ) {
  return { type: FETCH_SUCCESS, payload: { data: stats }};
}

function fetchFailure( error ) {
  return { type: FETCH_FAILURE, payload: error };
}

export function fetch() {
  return ( dispatch ) => {
    dispatch({ type: FETCH });

    const request = api.get( 'top_products' );

    return request
      .then(( response ) => {
        Deserializer.deserialize( response.data ).then(( result ) => {
          dispatch( fetchSuccess( result ));
        });
      })
      .catch(( error ) => {
        const apiError = new ApiError( error.response );

        dispatch( fetchFailure( apiError ));

        throw apiError;
      });
  };
}
