import api from 'src/explore/services/api';

// Actions

export const CREATE = 'cart/comment/CREATE';
export const CREATE_SUCCESS = 'cart/comment/CREATE_SUCCESS';
export const CREATE_FAILURE = 'cart/comment/CREATE_FAILURE';

// Action creators

export function createSuccess( comment ) {
  return { type: CREATE_SUCCESS, payload: comment };
}

export function createFailure( error ) {
  return { type: CREATE_FAILURE, payload: error };
}
export function create( params ) {
  return ( dispatch ) => {
    dispatch({ type: CREATE });

    const request = api.patch( 'comment', params );

    return request
      .then(( response ) => dispatch( createSuccess( response.data )))
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( createFailure( error.response.data ));
        }
      });
  };
}
