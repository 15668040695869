import api from 'src/explore/services/api';

// Actions

export const COMPLETE = 'Confirmation/COMPLETE';
export const COMPLETE_FAILURE = 'Confirmation/COMPLETE_FAILURE';

// Action creators

function completeFailure( error ) {
  return { type: COMPLETE_FAILURE, payload: error };
}

export function complete( cardInfo, stripeToken ) {
  return ( dispatch ) => {
    dispatch({ type: COMPLETE });

    const cardData = {
      name_on_card: cardInfo.name,
    };
    const billingData = {
      email: cardInfo.email,
      phone: cardInfo.phone,
    };

    const request = api.put( 'payment', {
      payment: {
        card_info: cardData,
        billing_address: billingData,
        stripe_token: stripeToken,
      },
    });

    return request
      .then(() => true )
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( completeFailure( error.response.data ));
        }
      });
  };
}
