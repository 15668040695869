// Actions

export const SHOW = 'Backdrop/SHOW';
export const HIDE = 'Backdrop/HIDE';
export const SHOW_BACKDROP = 'Backdrop/SHOW_BACKDROP';

// Action creators

export function show() {
  return { type: SHOW };
}

export function showBackdrop() {
  return { type: SHOW_BACKDROP };
}

export function hide() {
  return { type: HIDE };
}
