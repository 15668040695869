import { LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE } from 'src/explore/data/auth/actions';

const loadingReducer = ( state: boolean = false, action: { type: string }) => {
  switch ( action.type ) {
    case LOGIN:
      return true;
    case LOGIN_SUCCESS:
    case LOGIN_FAILURE:
      return false;
    default:
      return state;
  }
};

export default ( state: { loading?: boolean } = {}, action: { type: string }) => ({
  loading: loadingReducer( state.loading, action ),
});
