import * as api from 'recombee-js-api-client';
import camelCaseKeys from 'camelcase-keys';

import { Deserializer } from 'src/explore/services/api';

class Recombee {
  constructor() {
    if ( window.__data ) {
      this.client = new api.ApiClient(
        window.__data.recombeeDatabaseId,
        window.__data.recombeePublicKey,
        {
          baseUri: window.__data.recombeeClientApiUri,
        }
      );
    }
  }

  getItemsForUser( identity, quantity, options = {}) {
    return this.client.send(
      new api.RecommendItemsToUser( identity, quantity, {
        filter: "'visible' == true and 'is_sold_out' == false",
        cascadeCreate: true,
        diversity: 0.4,
        rotationRate: 0.2,
        returnProperties: true,
        includedProperties: [ 'json' ],
        ...options,
      })
    );
  }

  getItemsForItem( identity, itemId, quantity, options = {}) {
    return this.client.send(
      new api.RecommendItemsToItem( itemId, identity, quantity, {
        cascadeCreate: true,
        returnProperties: true,
        includedProperties: [ 'json' ],
        ...options,
      })
    );
  }

  getRecentlyViewedForUser( identity, quantity = 16, options = {}) {
    return this.client.send(
      new api.RecommendItemsToUser( identity, quantity, {
        filter: "'visible' == true",
        includedProperties: [ 'json' ],
        logic: {
          name: 'recombee:recently-viewed',
        },
        returnProperties: true,
        ...options,
      })
    );
  }

  sendItemView( identity, itemId, options = {}) {
    return this.client.send(
      new api.AddDetailView( identity, itemId, {
        cascadeCreate: true,
        ...options,
      })
    );
  }

  addFavorite( identity, itemId, options = {}) {
    return this.client.send(
      new api.AddBookmark( identity, itemId, {
        cascadeCreate: true,
        ...options,
      })
    );
  }

  static async deserializeOrNot( recomm ) {
    const parsed = JSON.parse( recomm.values.json );
    if ( parsed.data ) {
      const deserializedJson = await Deserializer.deserialize( parsed );
      return deserializedJson[0];
    }
    return parsed;
  }

  static async extractJson( res ) {
    const json = await Promise.all(
      res.recomms
        .filter(( recomm ) => recomm.values.json )
        .map(( recomm ) => this.deserializeOrNot( recomm ))
    );
    return camelCaseKeys( json, { deep: true });
  }
}

export { Recombee };
