import api from 'src/explore/services/api';
import ApiError from 'src/explore/services/api/ApiError';

// Actions

export const FETCH = 'account/FETCH';
export const FETCH_SUCCESS = 'account/FETCH_SUCCESS';
export const FETCH_FAILURE = 'account/FETCH_FAILURE';

// Action creators

function fetchSuccess( account, meta ) {
  return { type: FETCH_SUCCESS, payload: { data: account, meta }};
}

function fetchFailure( apiError ) {
  return { type: FETCH_FAILURE, payload: apiError };
}

export function fetch( email ) {
  return ( dispatch ) => {
    dispatch({ type: FETCH });

    const request = api.get( 'account', { params: { email }});

    return request
      .then(( response ) => {
        dispatch( fetchSuccess( response.data.data, response.data.meta ));

        return response.data;
      })
      .catch(( error ) => {
        const apiError = new ApiError( error.response );

        dispatch( fetchFailure( apiError ));

        throw apiError;
      });
  };
}
