import api from 'src/explore/services/api';
import ApiError from 'src/explore/services/api/ApiError';

// Actions

export const FETCH = 'reward_promos/FETCH';
export const FETCH_SUCCESS = 'reward_promos/FETCH_SUCCESS';
export const FETCH_FAILURE = 'reward_promos/FETCH_FAILURE';

// Action creators

function fetchSuccess( promos ) {
  return { type: FETCH_SUCCESS, payload: { data: promos }};
}

function fetchFailure( apiError ) {
  return { type: FETCH_FAILURE, payload: apiError };
}
export function fetch() {
  return ( dispatch ) => {
    dispatch({ type: FETCH });

    const request = api.get( 'reward_promotions' );

    return request
      .then(( response ) => {
        dispatch( fetchSuccess( response.data ));

        return response.data;
      })
      .catch(( error ) => {
        const apiError = new ApiError( error.response );

        dispatch( fetchFailure( apiError ));

        throw apiError;
      });
  };
}
