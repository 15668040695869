import { FETCH as FETCH_CART } from 'src/explore/data/cart/actions';
import { CREATE_SUCCESS, CREATE_FAILURE } from 'src/explore/data/cart/promo_codes/actions';
import { OPEN, CLOSE, TOGGLE, CLEAR_ERRORS } from './actions';

const initialState = {
  open: false,
  error: null,
};

export const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case OPEN:
      return {
        ...state,
        open: true,
      };
    case CLOSE:
    case CREATE_SUCCESS:
      return {
        ...state,
        open: false,
      };
    case TOGGLE:
      return {
        ...state,
        open: !state.open,
      };
    case CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.message,
        helpUrl: action.payload.helpUrl,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        helpUrl: null,
      };
    case FETCH_CART:
      return {
        ...state,
        open: false,
        error: null,
        helpUrl: null,
      };
    default:
      return state;
  }
};
