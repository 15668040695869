import api from 'src/explore/services/api';
import ApiError from 'src/explore/services/api/ApiError';

// Actions

export const FETCH = 'AccountStats/data/stats/FETCH';
export const FETCH_SUCCESS = 'AccountStats/data/stats/FETCH_SUCCESS';
export const FETCH_FAILURE = 'AccountStats/data/stats/FETCH_FAILURE';

// Action creators

function fetchSuccess( stats ) {
  return { type: FETCH_SUCCESS, payload: { data: stats }};
}

function fetchFailure( error ) {
  return { type: FETCH_FAILURE, payload: error };
}

export function fetch() {
  return ( dispatch ) => {
    dispatch({ type: FETCH });

    const request = api.get( 'account_stats' );

    return request
      .then(( response ) => dispatch( fetchSuccess( response.data )))
      .catch(( error ) => {
        const apiError = new ApiError( error.response );

        dispatch( fetchFailure( apiError ));

        throw apiError;
      });
  };
}
