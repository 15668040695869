import api from 'src/explore/services/api';
import ApiError from 'src/explore/services/api/ApiError';

// Actions

export const FETCH = 'admin/FETCH';
export const FETCH_SUCCESS = 'admin/FETCH_SUCCESS';
export const FETCH_FAILURE = 'admin/FETCH_FAILURE';

// Action creators

function fetchSuccess( admin, meta ) {
  return { type: FETCH_SUCCESS, payload: { data: admin, meta }};
}

function fetchFailure( apiError ) {
  return { type: FETCH_FAILURE, payload: apiError };
}

export function fetch() {
  return ( dispatch ) => {
    dispatch({ type: FETCH });

    const request = api.get( 'current_admin_user' );

    return request
      .then(( response ) => {
        dispatch( fetchSuccess( response.data.data, response.data.meta ));

        return response.data;
      })
      .catch(( error ) => {
        const apiError = new ApiError( error.response );

        dispatch( fetchFailure( apiError ));

        throw apiError;
      });
  };
}
