import apiReducer from 'src/shared/reducers/api_data';

import {
  FETCH,
  CREATE,
  FETCH_SUCCESS,
  CREATE_SUCCESS,
  FETCH_FAILURE,
  CREATE_FAILURE,
} from './actions';

export default apiReducer({
  initActions: [ FETCH, CREATE ],
  successActions: [ FETCH_SUCCESS, CREATE_SUCCESS ],
  failureActions: [ FETCH_FAILURE, CREATE_FAILURE ],
});
