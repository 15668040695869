import { TOGGLE as TOGGLE_ADDRESS_BOOK } from './actions';
import {
  FETCH as FETCH_ADDRESSES,
  FETCH_SUCCESS as FETCH_ADDRESSES_SUCCESS,
  FETCH_FAILURE as FETCH_ADDRESSES_FAILURE,
} from '../../data/addresses/actions';
import dataReducer from '../../data/addresses/reducer';
import {
  UPDATE as UPDATE_ADDRESS,
  UPDATE_SUCCESS as UPDATE_ADDRESS_SUCCESS,
  UPDATE_FAILURE as UPDATE_ADDRESS_FAILURE,
} from '../../data/shipping_address/actions';

const initialState = {
  open: false,
  loading: false,
  selectedAddress: null,
};

const openReducer = ( state, action ) => {
  switch ( action.type ) {
    case TOGGLE_ADDRESS_BOOK:
      return !state;
    default:
      return state;
  }
};

const updateReducer = ( state, action ) => {
  switch ( action.type ) {
    case UPDATE_ADDRESS:
    case UPDATE_ADDRESS_SUCCESS:
      return action.shipping_address;
    case UPDATE_ADDRESS_FAILURE:
      return null;
    default:
      return state;
  }
};

const loadingReducer = ( state, action ) => {
  switch ( action.type ) {
    case FETCH_ADDRESSES:
      return true;
    case FETCH_ADDRESSES_SUCCESS:
    case FETCH_ADDRESSES_FAILURE:
      return false;
    default:
      return state;
  }
};

export default ( state = initialState, action ) => {
  switch ( action.type ) {
    default:
      return {
        ...state,
        open: openReducer( state.open, action ),
        loading: loadingReducer( state.loading, action ),
        selectedAddress: updateReducer( state.selectedAddress, action ),
        data: dataReducer( state.data, action ),
      };
  }
};
