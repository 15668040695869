// Events
export const EVENT_ADD_TO_CART = 'Add to Cart';
export const EVENT_VIEW_CART = 'Cart Viewed';
export const EVENT_CC_ADDED = 'Credit Card Added';
export const EVENT_REFERRAL_ONBOARDING_COMPLETED = 'Referral Onboarding Conversion Completed';
export const EVENT_REFERRAL_ONBOARDING_FORCED_OPEN = 'Referral Onboarding Modal Opened';
export const EVENT_RAF_LINK_CLICKED = 'Refer A Friend Link Clicked';
export const EVENT_RAF_COMPLETED = 'Refer A Friend Conversion Completed';
export const EVENT_RAF_FORCED_OPEN = 'Refer A Friend Modal Opened';

const DEFAULT_INTEGRATIONS = {
  All: false,
  'Facebook Pixel': true,
  'Pinterest Tag': true,
  'Google Tag Manager': true,
};

const track = ( event, properties, integrations = DEFAULT_INTEGRATIONS ) => {
  // We use analytics instead of GA
  if ( window.analytics ) {
    window.analytics.track( event, properties, { integrations });
  }
};

export default {
  trackEvent: ( event, properties = null ) => track( event, properties ),
};
