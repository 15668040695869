import api from 'src/explore/services/api';

// Actions

export const FETCH = 'Shipping/data/addresses/FETCH';
export const FETCH_SUCCESS = 'Shipping/data/addresses/FETCH_SUCCESS';
export const FETCH_FAILURE = 'Shipping/data/addresses/FETCH_FAILURE';

// Action creators

export function fetch() {
  return ( dispatch ) => {
    dispatch({ type: FETCH });

    const request = api.get( 'saved_addresses' );

    return request
      .then(( response ) => dispatch( fetchSuccess( response.data )))
      .catch(( error ) => {
        if ( error.response ) {
          dispatch( fetchFailure( error.response.data ));
        }
      });
  };
}

function fetchSuccess( addresses ) {
  return { type: FETCH_SUCCESS, payload: { data: addresses }};
}

function fetchFailure( error ) {
  return { type: FETCH_FAILURE, payload: error };
}
