// Actions

export const BUILD = 'Payment/BUILD';
export const CANCEL_BUILD = 'Payment/CANCEL_BUILD';

export const STRIPE_ERROR = 'Payment/STRIPE_ERROR';

// Action creators

export function build() {
  return { type: BUILD };
}

export function cancelBuild() {
  return { type: CANCEL_BUILD };
}

export function stripeError( error ) {
  return { type: STRIPE_ERROR, payload: error };
}
